<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              title="Training Partners"
              :data-source="dataSource"
              :total-count="totalCount"
              @on-delete="(v) => onDelete(v)"
              @on-open-modal="(v) => onOpenModal(v)"
              @on-update-cancel="(v) => loadTrainingPartners()"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
            >
              <template #columns>
                <DxColumn
                  :data-field="item.field"
                  :caption="item.caption"
                  v-for="(item, index) in dataColumns"
                  :key="index"
                />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="modalShow"
      centered
      @hide="closeModal()"
      no-close-on-backdrop
    >
      <validation-observer ref="userForm">
        <b-row>
          <b-col>
            <b-form-group label="Name" label-for="v-name">
              <b-form-input v-model="formData.name" />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>

      <template #modal-footer>
        <b-button
          size="sm"
          @click="closeModal()"
          variant="danger"
          class="bg-darken-4"
        >
          Cancel
        </b-button>
        <b-button
          size="sm"
          @click="onSubmit()"
          variant="primary"
          class="bg-darken-4"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import trainingPartnerApi from '@api/training_partners';
import { queryParameters } from '@/schema';

export default {
  name: 'TrainingPartnersPage',
  components: {},
  data: () => ({
    modalShow: false,
    dataSource: [],
    formData: {
      id: 0,
      name: '',
    },
    dataColumns: [{ caption: 'Name', field: 'name' }],
    totalCount: 0,
    page: 0,
    size: 0,
  }),
  computed: {},
  watch: {
    page(v) {
      this.loadTrainingPartners();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadTrainingPartners();
      }
    },
  },
  mounted() {
    this.loadTrainingPartners();
  },
  methods: {
    loadTrainingPartners() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      trainingPartnerApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            this.dataSource = data;

            this.totalCount = meta.page.total;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      let app;

      if (this.formData.id) {
        app = trainingPartnerApi.update(this.formData);
      } else {
        app = trainingPartnerApi.add(this.formData);
      }

      if (app) {
        app
          .then(({ data }) => {
            this.closeModal();
            this.loadTrainingPartners();
            this.toastConfig();
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      }
    },
    onOpenModal(event) {
      this.modalShow = event.value;

      if (event.name === 'edit') {
        this.onEdit(event.data);
      }
    },
    onEdit(data) {
      this.formData = data;
    },
    onDelete(event) {
      trainingPartnerApi
        .delete(event)
        .then(() => {
          this.loadTrainingPartners();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    closeModal() {
      this.modalShow = false;
      const defaultForm = {
        id: 0,
        name: '',
      };

      this.$nextTick(() => {
        this.formData = Object.assign({}, defaultForm);
      });
    },
  },
};
</script>

<style></style>
